import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulImage } from '@ecomm/contentful/components'
import { ContentfulRichText } from '@ecomm/shared-components'
import classNames from 'classnames'

import { GridSchema } from '../../../templates/ProtectorsTemplate/schema'

const gridOpts = (idx: number): Options => ({
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const btnProps: { readonly text: string } = node.data.target

      return (
        <button
          className={classNames(
            'btn mt-auto btn-outlined btn-outlined-primary',
            {
              vfyps_mil: idx === 0,
              vfyps_med: idx === 1,
              vfyps_tea: idx === 2,
              vfyps_fir: idx === 3
            }
          )}
        >
          {btnProps.text}
        </button>
      )
    }
  }
})

export default function CardGrid({ headline, gridItems }: GridSchema) {
  return (
    <div className="w-full grid grid-cols-1 p-4 md:p-8 lg:p-10 gap-4 md:gap-8 lg:gap-10 md:grid-cols-2 lg:grid-cols-4 bg-neutral-light-50 rounded-base">
      <h2 className="col-span-1 md:col-span-2 lg:col-span-4 text-center mb-0">
        {headline}
      </h2>
      {gridItems.map((item, i) => (
        <div
          className="col-span-1 prose-h3:text-body-size prose-h3:mb-3 prose-h3:leading-normal rounded-base overflow-hidden bg-white"
          key={`grid-item-${i}`}
        >
          <ContentfulImage
            classNameOverride="w-full h-48"
            description={item.icon.description}
            originalHeight={item.icon.file.details.image.height}
            originalWidth={item.icon.file.details.image.width}
            url={item.icon.file.url}
          />
          <div className="p-4 md:p-6 flex flex-col h-52 md:h-64">
            <ContentfulRichText {...item.text} optionsCustom={gridOpts(i)} />
          </div>
        </div>
      ))}
    </div>
  )
}
